import { CharacterCard } from "./characterCard";

export default function PlaceholderCharacterCard() {
  return (
    <CharacterCard
      loading={true}
      character={{
        id: 1,
        traits: ["test", "loading", "don't read me pls"],
        avatarUrl: "",
        altText: "",
        avatarUrlHd: "",
        name: "Character",
        description:
          "I'm a placeholder character. You can't read this unless something goes wrong.",
        badges: ["test", "loading", "don't read me pls"],
      }}
    />
  );
}
