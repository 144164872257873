import { ReactNode, useCallback } from "react";
import { Flex, Heading, ScrollArea, Section, Text } from "@radix-ui/themes";
import { ScenarioCard } from "@/pages/app/home/scenarioCard";
import { usePostHog } from "posthog-js/react";
import { FrontendScenario } from "@/backend/frontendScenario";
import React from "react";
import { useAnalytics } from "@/analyticsContext";

export const ScenarioHomeRow = React.memo(function ScenarioHomeRow({
  scenarios,
  title,
  subtitle,
  loadEarly = false,
}: {
  title: string;
  subtitle: string;
  scenarios: FrontendScenario[];
  loadEarly?: boolean;
}) {
  const posthog = usePostHog();
  const analytics = useAnalytics();
  const handleScenarioClick = useCallback(
    (scenario: FrontendScenario) => {
      analytics.capture("homePage.homeRowScenarioClicked", {
        scenarioId: scenario.id,
        scenarioName: scenario.name,
        rowTitle: title,
        rowSubtitle: subtitle,
      });
      posthog.capture("home_scenario_clicked", {
        scenarioId: scenario.id,
        scenarioName: scenario.name,
        homeRowTitle: title,
        homeRowSubtitle: subtitle,
      });
    },
    [posthog, title, subtitle],
  );

  return (
    <Section size={"1"} style={{ gap: "3rem" }} overflow={"visible"}>
      <Flex gap={"4"} direction={"column"} overflow={"visible"}>
        <Heading>{title}</Heading>
        {false && <Text align={"left"}>{subtitle}</Text>}
        <ScrollArea
          type={"auto"}
          scrollbars={"horizontal"}
          size={"3"}
          style={{
            paddingTop: "10px",
            paddingBottom: "30px",
            marginTop: "-10px",
          }}
        >
          <Flex direction={"row"} align={"center"} gap={"4"}>
            {scenarios?.map((scenario) => (
              <ScenarioCard
                key={scenario.id}
                scenario={scenario}
                loadEarly={loadEarly}
                onClicked={() => handleScenarioClick(scenario)}
              />
            ))}
          </Flex>
        </ScrollArea>
      </Flex>
    </Section>
  );
});
