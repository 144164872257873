export enum SearchScenarioTime {
  Day = "day",
  Week = "week",
  Month = "month",
  AllTime = "allTime",
}

export enum SearchScenarioType {
  WordCount = "wordCount",
  Random = "random",
  SetOrder = "setOrder",
  Similarity = "similarity",
  SemanticSimilarity = "semanticSimilarity",
}
