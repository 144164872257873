import { memo, useEffect } from "react";
import { trpc } from "@/utils/trpc";
import { RenderableUserProfile } from "@/pages/api/trpc/user/renderableUserProfile";
import { toast } from "sonner";
import * as Sentry from "@sentry/nextjs";

export const Fingerprint = memo(function Fingerprint({
  userProfile,
}: {
  userProfile: RenderableUserProfile | undefined;
}) {
  const updateFingerprint = trpc.users.updateFingerprint.useMutation({});
  useEffect(() => {
    if (userProfile) {
      const loadFingerprint = async () => {
        const warning = await updateFingerprint.mutateAsync({
          fingerprint: undefined, //Send IP address first
        });
        if (warning) {
          toast.warning(warning, {
            position: "top-center",
          });
        }
        try {
          const storedFingerprint = window.localStorage.getItem("security-id");
          if (storedFingerprint) {
            await updateFingerprint.mutateAsync({
              fingerprint: storedFingerprint,
            });
          } else {
            const FingerprintJS = (
              await import("@fingerprintjs/fingerprintjs-pro")
            ).default;
            const fpPromise = FingerprintJS.load({
              apiKey: "fRIfzyresvlJICbMt2bQ",
            });

            // Analyze the visitor when necessary.
            const fp = await fpPromise;
            const result = await fp.get();
            window.localStorage.setItem("security-id", result.visitorId);
            await updateFingerprint.mutateAsync({
              fingerprint: result.visitorId,
            });
          }
        } catch (error) {
          console.error("Error loading or using Fingerprint:" + error);
          Sentry.captureException(error);
        }
      };

      loadFingerprint();
    }
  }, [userProfile]); // Remove dependencies to ensure it runs only once
  return <></>;
});
