import { CharacterSortType } from "@/backend/services/characters/characterSortType";
import { Combobox } from "@/components/ui/combobox";
import { trpc } from "@/utils/trpc";
import {
  Flex,
  TextField,
  Badge,
  Select,
  Dialog,
  Text,
  Button,
  Separator,
  Spinner,
} from "@radix-ui/themes";
import { useState } from "react";
import { MdClose } from "react-icons/md";
import { PiMagnifyingGlass, PiPencil } from "react-icons/pi";

export function TagSelector({
  setTags,
  tags,
}: {
  setTags: React.Dispatch<React.SetStateAction<Set<string>>>;
  tags: Set<string>;
}) {
  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <Button>Edit profile</Button>
      </Dialog.Trigger>

      <Dialog.Content maxWidth="450px">
        <Dialog.Title>Edit profile</Dialog.Title>
        <Dialog.Description size="2" mb="4">
          Make changes to your profile.
        </Dialog.Description>

        <Flex direction="column" gap="3">
          <label>
            <Text as="div" size="2" mb="1" weight="bold">
              Name
            </Text>
            <TextField.Root
              defaultValue="Freja Johnsen"
              placeholder="Enter your full name"
            />
          </label>
          <label>
            <Text as="div" size="2" mb="1" weight="bold">
              Email
            </Text>
            <TextField.Root
              defaultValue="freja@example.com"
              placeholder="Enter your email"
            />
          </label>
        </Flex>

        <Flex gap="3" mt="4" justify="end">
          <Dialog.Close>
            <Button variant="soft" color="gray">
              Cancel
            </Button>
          </Dialog.Close>
          <Dialog.Close>
            <Button>Save</Button>
          </Dialog.Close>
        </Flex>
      </Dialog.Content>
    </Dialog.Root>
  );
}

export function SearchModule({
  searchTerm,
  setSearchTerm,
  setTags,
  tags,
  setSearchSortType,
  searchSortType,
}: {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  setTags: React.Dispatch<React.SetStateAction<Set<string>>>;
  tags: Set<string>;
  setSearchSortType: React.Dispatch<React.SetStateAction<CharacterSortType>>;
  searchSortType: CharacterSortType;
}) {
  const genderTags = [
    { tag: "male", label: "Male" },
    { tag: "female", label: "Female" },
    { tag: "non-binary", label: "Non-Binary" },
  ];
  const categoryTags = [
    { tag: "video game characters", label: "Video Game Characters" },
    { tag: "music and bands", label: "Music & Bands" },
    { tag: "movies and theater", label: "Movies & Theater" },
    { tag: "tv shows", label: "TV Shows" },
    { tag: "cartoons and comics", label: "Cartoons & Comics" },
    { tag: "books and literature", label: "Books & Literature" },
    { tag: "anime and manga", label: "Anime & Manga" },
    { tag: "celebrities and real people", label: "Celebrities & Real People" },
    { tag: "other media", label: "Other Media" },
  ];
  const [tagSearchTerm, setTagSearchTerm] = useState<string>("");
  const tagListRequest = trpc.character.getTagList.useQuery(
    {
      searchTerm: tagSearchTerm,
    },
    {
      enabled: tagSearchTerm.length > 0,
    },
  );
  const tagSuggestions = tagListRequest.data;
  return (
    <Flex direction={"column"} gap={"2"} justify={"center"} align={"center"}>
      {tags.size === 0 && (
        <Flex width={"100%"} justify={"center"} align={"center"} gap={"2"}>
          <TextField.Root
            size={"3"}
            className={"w-full max-w-xl"}
            onChange={(e) => {
              setSearchTerm(e.currentTarget.value.trim());
            }}
            placeholder="Search for a character"
          >
            <TextField.Slot>
              <PiMagnifyingGlass className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </TextField.Slot>
          </TextField.Root>
        </Flex>
      )}

      {searchTerm.length == 0 && (
        <Flex direction={"column"} gap={"2"} align={"center"}>
          <Flex gap={"2"}>
            {Array.from(tags).map((tag) => (
              <Badge
                size={"3"}
                key={tag}
                onClick={() =>
                  setTags((prev) => {
                    const newSet = new Set(prev);
                    newSet.delete(tag);
                    return newSet;
                  })
                }
              >
                {tag}
                <MdClose />
              </Badge>
            ))}
          </Flex>
          <Flex direction={"row"} wrap={"wrap"} gap={"2"} align={"center"}>
            {genderTags.map((gender) => (
              <TagBadge
                tag={gender.tag}
                setTags={setTags}
                tags={tags}
                mutuallyExclusiveTags={genderTags.map((g) => g.tag)}
              >
                {gender.label}
              </TagBadge>
            ))}
          </Flex>
          <Flex gap={"2"} wrap={"wrap"} align={"center"} justify={"center"}>
            {categoryTags.map((category) => (
              <TagBadge
                tag={category.tag}
                setTags={setTags}
                tags={tags}
                mutuallyExclusiveTags={categoryTags.map((c) => c.tag)}
              >
                {category.label}
              </TagBadge>
            ))}
            <Combobox
              value={tagSearchTerm}
              setValue={setTagSearchTerm}
              onInput={(value: string) => {
                setTagSearchTerm(value);
              }}
              items={
                tagSuggestions?.map((tag) => ({
                  label: tag.tag,
                  value: tag.tag,
                })) ?? []
              }
              onSelect={(value: string) => {
                setTags((prev) => {
                  const newSet = new Set(prev);
                  newSet.add(value);
                  return newSet;
                });
                setTagSearchTerm("");
              }}
              placeholder="Search for a tag"
              emptyContent={
                tagListRequest.isLoading ? <Spinner /> : "No tags found"
              }
            />
          </Flex>
          <Flex align={"center"} gap={"2"}>
            <Text>Sort by</Text>
            <Select.Root
              size={"3"}
              value={searchSortType}
              onValueChange={(value: string) =>
                setSearchSortType(value as CharacterSortType)
              }
            >
              <Select.Trigger />
              <Select.Content>
                <Select.Item value="top">Top</Select.Item>
                <Select.Item value="new">New</Select.Item>
                <Select.Item value="trending">Trending</Select.Item>
              </Select.Content>
            </Select.Root>
          </Flex>
        </Flex>
      )}
    </Flex>
  );
}

function TagBadge({
  tag,
  setTags,
  tags,
  mutuallyExclusiveTags = [],
  children,
}: {
  tag: string;
  setTags: React.Dispatch<React.SetStateAction<Set<string>>>;
  tags: Set<string>;
  mutuallyExclusiveTags: string[];
  children: React.ReactNode;
}) {
  return (
    !tags.has(tag) && (
      <Badge
        size={"3"}
        variant="solid"
        onClick={() =>
          setTags((prev) => {
            const newTags = new Set(prev);
            mutuallyExclusiveTags.forEach((exclusiveTag) => {
              if (exclusiveTag !== tag) {
                newTags.delete(exclusiveTag);
              }
            });
            newTags.add(tag);
            return newTags;
          })
        }
      >
        {children}
      </Badge>
    )
  );
}
